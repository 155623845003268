import React from "react"

const BurbanKitchen = ({ className }) => {
  return (
    <svg
      width="177.099"
      height="60.485"
      viewBox="0 0 177.099 60.485"
      className={className}
    >
      <path
        d="M73.22,78.363H71.005l-3.683,3.5v-3.5H65.548V86.4h1.774V83.878l1.291-1.217L71.6,86.4h2.226l-3.954-4.9Z"
        transform="translate(-21.88 -26.157)"
        fill="currentColor"
      />
      <path
        d="M89.709,78.363H87.947v8.043h1.762Z"
        transform="translate(-29.356 -26.157)"
        fill="currentColor"
      />
      <path
        d="M101.11,79.89h2.918V86.4h1.758V79.89H108.7V78.363H101.11Z"
        transform="translate(-33.75 -26.157)"
        fill="currentColor"
      />
      <path
        d="M126.224,85.009a2.748,2.748,0,0,1-.062-5.492,4.284,4.284,0,0,1,2.636,1.036l1.106-1.159a6.185,6.185,0,0,0-3.892-1.4,4.265,4.265,0,1,0,.062,8.526,6,6,0,0,0,4.089-1.643l-1.121-1.159A4.123,4.123,0,0,1,126.224,85.009Z"
        transform="translate(-40.603 -26.032)"
        fill="currentColor"
      />
      <path
        d="M150.516,81.343h-3.791v-2.98H144.95V86.4h1.774V82.831h3.791V86.4h1.774V78.363h-1.774Z"
        transform="translate(-48.383 -26.157)"
        fill="currentColor"
      />
      <path
        d="M194.806,83.349h-.011l-4.1-4.986h-1.515V86.4h1.773V81.428h.012l4.089,4.974h1.526V78.363h-1.774Z"
        transform="translate(-63.146 -26.157)"
        fill="currentColor"
      />
      <path
        d="M167.906,86.4h6.682V84.891h-4.92V82.966h4.92V81.451h-4.92V79.89h4.92V78.363h-6.682Z"
        transform="translate(-56.046 -26.157)"
        fill="currentColor"
      />
      <path
        d="M0,0V58.556H36.306V55.445H3.111V3.107h170.88V55.445H140.819v3.111H177.1V0Z"
        fill="currentColor"
      />
      <path
        d="M76.295,21.714h-4.1a.139.139,0,0,0-.139.139V36.122a2.319,2.319,0,0,1-2.524,2.524H67.212a2.319,2.319,0,0,1-2.524-2.524V21.853a.139.139,0,0,0-.139-.139H60.3a.139.139,0,0,0-.139.139v15.51c0,3.745,1.9,5.647,5.647,5.647h4.97c3.745,0,5.647-1.9,5.647-5.647V21.853a.139.139,0,0,0-.128-.139Z"
        transform="translate(-20.081 -7.248)"
        fill="currentColor"
      />
      <path
        d="M76.393,60.78H60.223v3.7h16.17Z"
        transform="translate(-20.102 -20.288)"
        fill="currentColor"
      />
      <path
        d="M38.511,33.351V27.357c0-3.745-1.932-5.643-5.689-5.643h-10.6V51.292H32.837c3.776,0,5.689-1.9,5.689-5.643V39.744c0-1.832-1.975-2.678-2.064-2.705L35.3,36.574l1.105-.429A3.374,3.374,0,0,0,38.511,33.351Zm-4.124,11.32a2.319,2.319,0,0,1-2.524,2.524H26.229V38.727h5.635a2.322,2.322,0,0,1,2.524,2.528Zm0-12.785a2.314,2.314,0,0,1-2.524,2.481H26.229V25.811h5.635a2.319,2.319,0,0,1,2.524,2.524Z"
        transform="translate(-7.418 -7.248)"
        fill="currentColor"
      />
      <path
        d="M231.278,51.153a1.2,1.2,0,0,0,.073.139h3.514V21.714h-4.1V41.653l-9.774-19.8a.554.554,0,0,0-.073-.135h-3.514V51.3h4.1V31.152Z"
        transform="translate(-72.569 -7.248)"
        fill="currentColor"
      />
      <path
        d="M186.6,21.714h-6.206a.274.274,0,0,1,0,.046l-7.344,29.531h4.128l1.546-6.215h9.515l1.577,6.215h4.128L186.6,21.741A.092.092,0,0,1,186.6,21.714Zm-6.864,19.711L182.895,27.8l.309-1.264h.545l.309,1.264,3.158,13.627Z"
        transform="translate(-57.763 -7.248)"
        fill="currentColor"
      />
      <path
        d="M111.934,41.355l.46-.286c1.414-.873,2.129-2.5,2.129-4.789V27.357c0-3.745-1.9-5.643-5.642-5.643H98.267V51.292h4.275V41.923h5.287s2.276,9.357,2.28,9.368h4.228Zm-1.662-6.184a2.318,2.318,0,0,1-2.524,2.524h-5.206V25.942h5.186a2.322,2.322,0,0,1,2.524,2.528Z"
        transform="translate(-32.801 -7.248)"
        fill="currentColor"
      />
      <path
        d="M147.37,21.714H136.753V51.292H147.37c3.776,0,5.689-1.9,5.689-5.643V39.744c0-1.832-1.979-2.678-2.064-2.705l-1.159-.464,1.109-.429c.282-.131,2.1-1.013,2.1-2.794V27.357C153.058,23.612,151.146,21.714,147.37,21.714Zm1.546,22.957a2.318,2.318,0,0,1-2.524,2.524h-5.631V38.727h5.631a2.321,2.321,0,0,1,2.524,2.528Zm0-12.785a2.314,2.314,0,0,1-2.524,2.481h-5.631V25.811h5.631a2.318,2.318,0,0,1,2.524,2.524Z"
        transform="translate(-45.647 -7.248)"
        fill="currentColor"
      />
    </svg>
  )
}

export default BurbanKitchen
