import React from "react"

const DataStax = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 160 25"
      height="25"
      width="160"
    >
      <path
        fill="currentColor"
        d="M19.8444 2.92095C18.8507 1.92723 17.6161 1.20452 16.1104 0.72271C14.6048 0.240903 12.8281 0 10.7503 0H0V24H10.7503C12.798 24 14.6048 23.7591 16.1104 23.2773C17.6161 22.7955 18.8507 22.0728 19.8444 21.079C20.8381 20.0853 21.5609 18.8507 22.0427 17.345C22.5245 15.8394 22.7654 14.0627 22.7654 11.9849C22.7654 9.90715 22.5245 8.13049 22.0427 6.62484C21.5609 5.1192 20.8381 3.88457 19.8444 2.92095ZM16.2008 15.3275C15.9598 16.2309 15.5684 16.9536 15.0263 17.4956C14.4843 18.0376 13.7616 18.4291 12.8582 18.67C11.9548 18.9109 10.8708 19.0314 9.5458 19.0314H5.93225V4.99874H9.5458C10.8708 4.99874 11.9548 5.1192 12.8582 5.3601C13.7616 5.601 14.4843 5.99247 15.0263 6.5345C15.5684 7.07654 15.9598 7.79925 16.2008 8.70263C16.4417 9.60602 16.5621 10.6901 16.5621 12.0151C16.5621 13.34 16.4417 14.4241 16.2008 15.3275Z"
      ></path>
      <path
        fill="currentColor"
        d="M41.5257 8.13046C40.9234 7.5282 40.1706 7.07651 39.2371 6.80549C38.3036 6.50436 37.1894 6.38391 35.9247 6.38391H26.3789V10.7202H35.6236C36.286 10.7202 36.7377 10.8707 37.0389 11.1418C37.3099 11.4128 37.4604 11.8946 37.4604 12.5269V12.7377H30.8055C28.9686 12.7377 27.5834 13.2195 26.6198 14.1832C25.6562 15.1468 25.1744 16.5621 25.1744 18.3689C25.1744 20.1756 25.6562 21.5608 26.6198 22.5545C27.5834 23.5483 28.9987 24 30.8055 24H43.3024V13.7315C43.3024 12.4366 43.1518 11.3525 42.8808 10.419C42.5796 9.48554 42.1279 8.73272 41.5257 8.13046ZM37.5207 19.7239H32.4617C32.0401 19.7239 31.6788 19.6035 31.4379 19.3626C31.197 19.1217 31.0765 18.7904 31.0765 18.3689C31.0765 17.9172 31.197 17.5859 31.4379 17.345C31.6788 17.1041 32.01 16.9837 32.4617 16.9837H37.5207V19.7239Z"
      ></path>
      <path
        fill="currentColor"
        d="M54.2033 19.3024C53.9624 19.0615 53.8419 18.67 53.8419 18.0678V10.7202H59.2623V6.38398H53.8419V1.65625H47.9699V6.35387H45.5308V10.6901H47.9699V18.3689C47.9699 19.3928 48.0602 20.266 48.271 20.9887C48.4818 21.7115 48.783 22.2836 49.2347 22.7353C49.6863 23.187 50.2585 23.5182 50.9812 23.6989C51.7039 23.9097 52.5772 24 53.601 24H59.3225V19.6638H55.4379C54.8357 19.6638 54.4442 19.5433 54.2033 19.3024Z"
      ></path>
      <path
        fill="currentColor"
        d="M77.6913 8.13046C77.0891 7.5282 76.3363 7.07651 75.4028 6.80549C74.4693 6.50436 73.3551 6.38391 72.0903 6.38391H62.5445V10.7202H71.7892C72.4517 10.7202 72.9034 10.8707 73.2045 11.1418C73.4755 11.4128 73.6261 11.8946 73.6261 12.5269V12.7377H66.9711C65.1343 12.7377 63.7491 13.2195 62.7854 14.1832C61.8218 15.1468 61.34 16.5621 61.34 18.3689C61.34 20.1756 61.8218 21.5608 62.7854 22.5545C63.7491 23.5483 65.1644 24 66.9711 24H79.468V13.7315C79.468 12.4366 79.3174 11.3525 79.0464 10.419C78.7453 9.48554 78.2936 8.73272 77.6913 8.13046ZM73.6562 19.7239H68.5972C68.1757 19.7239 67.8143 19.6035 67.5734 19.3626C67.3325 19.1217 67.212 18.7904 67.212 18.3689C67.212 17.9172 67.3325 17.5859 67.5734 17.345C67.8143 17.1041 68.1455 16.9837 68.5972 16.9837H73.6562V19.7239Z"
      ></path>
      <path
        fill="currentColor"
        d="M95.0063 9.45545H90.5194C89.7967 9.45545 89.2246 9.27478 88.8632 8.88331C88.4718 8.49184 88.2911 7.94981 88.2911 7.2271C88.2911 6.50439 88.4718 5.96236 88.8632 5.57089C89.2547 5.17942 89.7967 4.99874 90.5194 4.99874H101.27V0H89.2246C86.8155 0 85.0088 0.602258 83.8042 1.77666C82.5997 2.95107 82.0276 4.75784 82.0276 7.19699C82.0276 9.63613 82.6298 11.4429 83.8042 12.6474C84.9787 13.8519 86.7854 14.4542 89.2246 14.4542H93.8017C95.3074 14.4542 96.0903 15.207 96.0903 16.7428C96.0903 18.2785 95.3375 19.0314 93.8017 19.0314H82.6901V24.0301H94.9761C97.3852 24.0301 99.192 23.4278 100.427 22.1932C101.661 20.9586 102.294 19.1518 102.294 16.7428C102.294 14.3337 101.661 12.527 100.427 11.3225C99.192 10.1179 97.3852 9.45545 95.0063 9.45545Z"
      ></path>
      <path
        fill="currentColor"
        d="M112.923 19.3024C112.683 19.0615 112.562 18.67 112.562 18.0678V10.7202H117.982V6.38398H112.562V1.65625H106.69V6.35387H104.251V10.6901H106.69V18.3689C106.69 19.3928 106.78 20.266 106.991 20.9887C107.202 21.7115 107.503 22.2836 107.955 22.7353C108.406 23.187 108.979 23.5182 109.701 23.6989C110.424 23.9097 111.297 24 112.321 24H118.043V19.6638H114.158C113.586 19.6638 113.164 19.5433 112.923 19.3024Z"
      ></path>
      <path
        fill="currentColor"
        d="M136.411 8.13046C135.809 7.5282 135.056 7.07651 134.123 6.80549C133.189 6.50436 132.075 6.38391 130.81 6.38391H121.265V10.7202H130.509C131.172 10.7202 131.624 10.8707 131.925 11.1418C132.196 11.4128 132.346 11.8946 132.346 12.5269V12.7377H125.691C123.854 12.7377 122.469 13.2195 121.506 14.1832C120.542 15.1468 120.06 16.5621 120.06 18.3689C120.06 20.1756 120.542 21.5608 121.506 22.5545C122.469 23.5483 123.885 24 125.691 24H138.188V13.7315C138.188 12.4366 138.038 11.3525 137.767 10.419C137.465 9.48554 137.014 8.73272 136.411 8.13046ZM132.376 19.7239H127.317C126.896 19.7239 126.534 19.6035 126.294 19.3626C126.053 19.1217 125.932 18.7904 125.932 18.3689C125.932 17.9172 126.053 17.5859 126.294 17.345C126.534 17.1041 126.866 16.9837 127.317 16.9837H132.376V19.7239Z"
      ></path>
      <path
        fill="currentColor"
        d="M153.034 15.1167L159.538 6.38391H153.275L149.691 11.473L146.108 6.38391H139.603L146.108 15.1167L139.242 24H145.807L149.511 18.7302L153.184 24H159.9L153.034 15.1167Z"
      ></path>
    </svg>
  )
}

export default DataStax
